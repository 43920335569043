import {axiosApiInstance} from "@/plugins/axios.js";

/**
 * @param {number} project_id
 * @returns {Promise<{data:Array<any>}>}
 */
async function get_agents(project_id){
    const res = await axiosApiInstance({
        url:process.env.VUE_APP_BACKEND+`/api/v3/village-agents/${project_id}`
    });
    return res.data;
}
export {get_agents}
