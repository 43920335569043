<template>
  <div class="d-flex">
    <b-col cols="9 pt-4">
      <div class="card-bdr card">
        <div class="d-flex pt-2 px-1">
          <div style="max-height: 400px; overflow-y: scroll; width: 20%">
            <b-nav vertical justified>
              <b-nav-item
                v-for="crop in crops"
                @click="
                  () => {
                    selcrop = crop;
                    getSeries();
                  }
                "
                :key="crop"
              >
                <span :class="{ 'text-dark': crop == selcrop }" class="text-capitalize">{{
                  crop
                }}</span>
                <b-icon-caret-right
                  class="position-relative float-right"
                  :class="{ 'text-dark': crop == selcrop }"
                ></b-icon-caret-right>
              </b-nav-item>
            </b-nav>
          </div>
          <div style="width: 80%">
            <div class="d-flex align-items-center">
              <b-col cols="3">
                <b-form-select
                  v-model="supplier_category"
                  @change="getSeriesByCategory"
                >
                  <b-form-select-option :value="null"
                    >All Suppliers</b-form-select-option
                  >
                  <b-form-select-option value="Farmer"
                    >Farmer</b-form-select-option
                  >
                  <b-form-select-option value="Agent"
                    >Agent</b-form-select-option
                  >
                </b-form-select>
              </b-col>
              <b-col cols="9">
                <div class="row align-items-center">
                  <b-col cols="3">
                    <b-form-group class="pt-3">
                      <b-form-select
                        v-model="timeline"
                        :options="timelines"
                        @change="selectTimeline"
                        name="radio-btn-outline"
                      >
                        <template #first>
                          <b-form-select-option :value="null" disabled
                            >All Time</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="9">
                    <date-filter v-show="timeline == 'cust'" @filter-date="filterdate"/>
                  </b-col>
                </div>
              </b-col>
            </div>
            <line-chart :series="series" :options="coptions"></line-chart>
          </div>
        </div>
      </div>
      <div class="w-100 mt-2">
        <div class="border pl-3 pt-2">
          <div class="d-flex">
            <div class="col-5 pr-2">
              <h5 class="my-2">Village Agents</h5>
              <b-table
                :items="vas"
                :fields="va_fields"
                :per-page="perPage"
                ref="selectableTable"
                selectable
                select-mode="single"
                selected-variant="info"
                :current-page="currentPage"
                @row-selected="onRowSelected"
              ></b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="vas.length"
                :per-page="perPage"
              ></b-pagination>
            </div>
            <div class="col-7 pt-2">
              <b-form inline>
                <b-form-group>
                  <b-form-select
                    :options="crops"
                    v-model="va_crop"
                    @change="
                      () => {
                        getAgentData();
                      }
                    "
                  >
                    <template #first>
                      <b-form-select-option :value="null"
                        >All Crops</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group>
                  <b-form-select
                    v-model="vtimeline"
                    :options="timelines"
                    @change="selectvTimeline"
                    name="radio-btn-outline"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >All Time</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-form>
              <date-filter v-show="vtimeline == 'cust'" @filter-date="vfilterdate"></date-filter>
              <line-chart :series="vseries" :options="voptions"></line-chart>
            </div>
          </div>
        </div>
      </div>
    </b-col>
    <b-col cols="3 py-4">
      <div class="mb-4">
        <h6 class="subtitle">Total farmers that have bulked produce</h6>
        <h2>{{farmer_count}}</h2>
      </div>
     <!--  <div class="mb-4">
        <h6 class="subtitle">Trained farmers' avg yield</h6>
        <h2 class="price-lg">5.00 <small>Tons/acre</small></h2>
      </div>
      <div>
        <h6 class="subtitle">Untrained farmers' avg yield</h6>
        <h2 class="price-lg">2.06 <small>Tons/acre</small></h2>
      </div> -->
    </b-col>
  </div>
</template>
<script>
import LineChart from "../../components/LineChart.vue";
import DateFilter from "./components/DateFilter.vue";
import { Produce } from "../../modules";
import { to_time } from "../../modules/produce/utils";
import { to_tonnes } from "../../../utils";
import { get_agents } from "../../../suppliers/subroutes/agents/api.calls";
import { chartOptions, dateUtils } from "../../modules/produce/ui.utils";
var _ = require("lodash");
export default {
  components: { LineChart, DateFilter },
  data() {
    const series = [
      {
        name: "Tonnes",
        data: [],
      },
    ];
    const format_label = (val) => to_tonnes(val);
    let vas = [];
    let va_fields = [
      { key: "va_name", label: "Name" },
      { key: "farmers", label: "Farmers" },
    ];
    const vseries = [
      {
        name: "Tonnes",
        data: [],
      },
    ];
    return {
      crops: [],
      selcrop: "",
      supplier_category: null,
      series,
      format_label,
      vas,
      va_fields,
      currentPage: 1,
      perPage: 6,
      va_crop: null,
      vseries,
      vaId: null,
      farmer_count:0,
      timelines: [
        { text: "This Season", value: "this_season" },
        { text: "Last Season", value: "last_season" },
        { text: "Date Range", value: "cust" },
      ],
      timeline: null,
      vtimeline: null,
      coptions: chartOptions(
        "No produce bulked yet",
        format_label,
        "Produce Bulked"
      ),
      voptions: chartOptions(
        "No produce bulked yet",
        format_label,
        "Produce Bulked"
      ),
    };
  },
  computed: {
    projectId() {
      return this.$store.state.project_id;
    },
  },
  mounted() {
    Produce.api.get_crops().then((data) => {
      this.crops = data.map((el) => el["crop"]);
      this.selcrop = this.crops[0];
      this.getSeries();
    });
    this.getAgents();
    Produce.api.aggregate_numeral({
      project_id:this.projectId,
      "aggregate[count,distinct]":"farmer_id"}).then(data=>{
      this.farmer_count = data;
    })
  },
  methods: {
    selectTimeline() {
      if (this.timeline != "cust") {
        const cb = dateUtils[this.timeline];
        let [min, max] = cb();
        this.coptions = chartOptions(
          "No produce bulked yet",
          this.format_label,
          "Produce Bulked",
          dateUtils.time.getMills(min),
          dateUtils.time.getMills(max)
        );
      }

    },
    filterdate(daterange){
      let [min,max] = daterange;
      this.coptions = chartOptions(
          "No produce bulked yet",
          this.format_label,
          "Produce Bulked",
          dateUtils.time.getMills(min),
          dateUtils.time.getMills(max)
        );
    },
    selectvTimeline() {
      if (this.vtimeline != "cust") {
        const cb = dateUtils[this.vtimeline];
        let [min, max] = cb();
        this.voptions = chartOptions(
          "No produce bulked yet",
          this.format_label,
          "Produce Bulked",
          dateUtils.time.getMills(min),
          dateUtils.time.getMills(max)
        );
      }
    },
    vfilterdate(daterange){
      let [min,max] = daterange;
      this.voptions = chartOptions(
          "No produce bulked yet",
          this.format_label,
          "Produce Bulked",
          dateUtils.time.getMills(min),
          dateUtils.time.getMills(max)
        );
    },
    getSeries() {
      Produce.api
        .aggregate_weekly({
          project_id: this.projectId,
          crop: this.selcrop,
          "aggregate[sum]": "quantity_supplied",
          supplier: this.supplier_category,
        })
        .then((data) => {
          const plot_data = data.map((el) => {
            return [to_time(el["week"], el["year"]), el["result"]];
          });
          this.series = [
            { name: "Tonnes", data: _.orderBy(plot_data, (el) => el[0]) },
          ];
        });
    },
    getSeriesByCategory() {
      this.getSeries();
    },
    getAgents() {
      get_agents(this.projectId).then((res) => {
        this.vas = res.data;
        this.$nextTick(() => {
          this.$refs.selectableTable.selectRow(1);
        });
      });
    },
    getAgentData() {
      Produce.api
        .aggregate_weekly({
          project_id: this.projectId,
          "aggregate[sum]": "quantity_supplied",
          vaId: this.vaId,
          crop: this.va_crop,
        })
        .then((data) => {
          const plot_data = data.map((el) => {
            return [to_time(el["week"], el["year"]), el["result"]];
          });
          this.vseries = [
            { name: "Tonnes", data: _.orderBy(plot_data, (el) => el[0]) },
          ];
        });
    },
    onRowSelected(items) {
      let va = items[0];
      this.vaId = va["vaId"];
      this.getAgentData();
    },
  },
};
</script>

